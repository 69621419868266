import * as React from "react";

const SVGAr = (props) => (
  <svg
    width="100"
    height="109"
    viewBox="0 0 100 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M62.8495 23.6543C66.0926 23.6543 69.0773 25.3381 70.6988 28.0824L83.6629 50.0232C85.309 52.809 85.3001 56.2634 83.6396 59.0736L70.7678 80.8581C69.1074 83.6684 66.0503 85.403 62.7581 85.403H36.83C33.5869 85.403 30.6023 83.7193 28.9807 80.9749L16.0167 59.0342C14.3706 56.2483 14.3795 52.794 16.04 49.9838L28.9117 28.1992C30.5722 25.389 33.6294 23.6543 36.9215 23.6543H62.8495Z"
      fill="#FBBA00"
    />
    <path
      d="M50 44.9199C44.48 44.9199 40 49.3999 40 54.9199C40 60.4399 44.48 64.9199 50 64.9199C55.52 64.9199 60 60.4399 60 54.9199C60 49.3999 55.52 44.9199 50 44.9199ZM57.46 52.0399L54.68 53.1899C54.4296 52.516 54.0363 51.9042 53.5271 51.3967C53.0179 50.8892 52.4048 50.498 51.73 50.2499L52.88 47.4699C54.98 48.2699 56.65 49.9399 57.46 52.0399ZM50 57.9199C48.34 57.9199 47 56.5799 47 54.9199C47 53.2599 48.34 51.9199 50 51.9199C51.66 51.9199 53 53.2599 53 54.9199C53 56.5799 51.66 57.9199 50 57.9199ZM47.13 47.4599L48.3 50.2399C47.6173 50.4887 46.9971 50.8833 46.4824 51.3962C45.9678 51.9091 45.5711 52.5281 45.32 53.2099L42.54 52.0499C42.9408 51.0059 43.5565 50.0579 44.3472 49.2671C45.1379 48.4764 46.086 47.8607 47.13 47.4599ZM42.54 57.7899L45.32 56.6399C45.5695 57.3199 45.9645 57.9372 46.4776 58.4485C46.9906 58.9598 47.6092 59.3528 48.29 59.5999L47.12 62.3799C46.0785 61.9769 45.1329 61.3603 44.3441 60.5697C43.5553 59.7792 42.9408 58.8323 42.54 57.7899ZM52.88 62.3799L51.73 59.5999C52.4078 59.3486 53.0228 58.9528 53.5322 58.4399C54.0416 57.9271 54.4333 57.3094 54.68 56.6299L57.46 57.7999C57.0581 58.8404 56.443 59.7854 55.6542 60.5742C54.8655 61.3629 53.9205 61.978 52.88 62.3799Z"
      fill="black"
    />
  </svg>
);

export default SVGAr;
