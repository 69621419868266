import * as React from "react";

const SVGAr = (props) => (
  <svg
    width="100"
    height="109"
    viewBox="0 0 100 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
	{...props}
  >
    <path
      d="M62.8495 23.1243C66.0926 23.1243 69.0773 24.8081 70.6988 27.5524L83.6629 49.4932C85.309 52.279 85.3001 55.7334 83.6396 58.5435L70.7678 80.3281C69.1074 83.1384 66.0503 84.873 62.7581 84.873H36.83C33.5869 84.873 30.6023 83.1893 28.9807 80.4449L16.0167 58.5042C14.3706 55.7183 14.3795 52.264 16.04 49.4538L28.9117 27.6692C30.5722 24.859 33.6294 23.1243 36.9215 23.1243H62.8495Z"
      fill="#FBBA00"
    />
    <g clip-path="url(#clip0_25_204)">
      <path
        d="M56 46.3899V42.3899M45 60.3899H43M57 60.3899H47M45 56.3899H43M57 56.3899H47M44 46.3899V42.3899M39 51.3899H61M39 65.3899H61V46.3899H39V65.3899Z"
        stroke="black"
        stroke-width="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_25_204">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(38 42.3899)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVGAr;
