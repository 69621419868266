import * as React from "react";

const SVGAr = (props) => (
  <svg
    width="100"
    height="109"
    viewBox="0 0 100 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M62.8496 23.1243C66.0927 23.1243 69.0773 24.8081 70.6988 27.5524L83.6629 49.4932C85.309 52.279 85.3001 55.7334 83.6397 58.5435L70.7678 80.3281C69.1074 83.1384 66.0503 84.873 62.7581 84.873H36.83C33.5869 84.873 30.6023 83.1893 28.9807 80.4449L16.0167 58.5042C14.3706 55.7183 14.3796 52.264 16.04 49.4538L28.9117 27.6692C30.5722 24.859 33.6294 23.1243 36.9215 23.1243H62.8496Z"
      fill="#FBBA00"
    />
    <g clip-path="url(#clip0_25_212)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M55.7017 42.8699C55.7913 42.726 55.9215 42.6119 56.0759 42.5421C56.2303 42.4722 56.4019 42.4497 56.5691 42.4773L60.9766 43.2127C61.2007 43.2501 61.4007 43.3749 61.5329 43.5598C61.665 43.7446 61.7183 43.9743 61.6811 44.1984L60.9474 48.6076C60.9194 48.7747 60.8425 48.9298 60.7264 49.0533C60.6103 49.1767 60.4602 49.2629 60.2951 49.3011C60.1299 49.3393 59.9572 49.3276 59.7987 49.2676C59.6402 49.2077 59.5031 49.1021 59.4046 48.9642L58.2148 47.3013L52.088 51.2167C51.8267 51.3839 51.5138 51.451 51.207 51.4057C50.9001 51.3603 50.62 51.2055 50.4183 50.9699L47.0017 46.9756L40.6126 50.2584C40.3093 50.4144 39.9565 50.4435 39.6318 50.3393C39.3071 50.2352 39.0371 50.0063 38.8811 49.703C38.7252 49.3998 38.6961 49.047 38.8003 48.7223C38.9044 48.3976 39.1333 48.1275 39.4366 47.9716L46.7308 44.2224C46.9884 44.0901 47.283 44.0486 47.5671 44.1046C47.8511 44.1606 48.1079 44.3109 48.296 44.531L51.6457 48.4482L56.72 45.2047L55.7308 43.8213C55.6325 43.6833 55.5773 43.5193 55.5721 43.35C55.5669 43.1807 55.612 43.0136 55.7017 42.8699ZM44.48 51.287L39.5051 54.1327C39.3086 54.245 39.1452 54.4071 39.0314 54.6028C38.9176 54.7985 38.8575 55.0207 38.8571 55.247V65.1042C38.8571 65.8139 39.4331 66.3899 40.1428 66.3899H44.48V51.287ZM46.6228 66.3899H52.9108V54.7122L50.084 56.1247L47.2588 51.1807C47.1159 50.9296 46.8924 50.7339 46.6246 50.6253L46.6228 66.3899ZM59.8571 66.3899H55.0537V53.6407L59.2828 51.5253C59.4789 51.4274 59.6966 51.3813 59.9155 51.3912C60.1344 51.4012 60.3471 51.4669 60.5334 51.5821C60.7197 51.6974 60.8735 51.8583 60.9802 52.0497C61.0868 52.241 61.1428 52.4565 61.1428 52.6756V65.1042C61.1428 65.4452 61.0074 65.7722 60.7663 66.0133C60.5251 66.2544 60.1981 66.3899 59.8571 66.3899Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_25_212">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(38 42.3899)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVGAr;
