import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import WhatWeDoHero from "../../components/what-we-do-hero";
import HowCanWeHelpLeft from "../../components/how-can-we-help-left";
import CtaBanner from "../../components/cta-banner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import SVGPatternedSidebar from "../../components/SVG/patterned-sidebar";
import SVGBusiness from "../../components/SVG/business-startergy";
import ServiceCard from "../../components/service-card";
import SVGLcdBgPoly1 from "../../components/SVG/lcd-bg-poly-1";
import SVGLargeBgBee from "../../components/SVG/large-bg-bee.js";
import SVGFBHp1 from "../../components/SVG/flying-bee-hp-1";
import SVGRecruitment from "../../components/SVG/leadership-new";
import SVGErHp from "../../components/SVG/employee-relation-hp";
import SVGDocumentation from "../../components/SVG/documentation";
import SVGSupport from "../../components/SVG/support";
import SVGFBLcd from "../../components/SVG/flying-bee-lcd";
import PhotoRight from "../../components/photo-right";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Container } from "react-bootstrap";

const HRGuildFord = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "guildford-banner" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      clientBaseImg: wpMediaItem(title: { eq: "guildford-solution" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      trainingImg: wpMediaItem(title: { eq: "team-guildford" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      rrsImg: wpMediaItem(title: { eq: "proactive-image-guildford" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      backgroundImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const isDarken = true;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const heroImg = data.heroImg?.localFile?.childImageSharp.gatsbyImageData;
  const trainingImg =
    data.trainingImg?.localFile?.childImageSharp.gatsbyImageData;
  const clientBaseImg =
    data.clientBaseImg?.localFile?.childImageSharp.gatsbyImageData;
  const backgroundImg =
    data.backgroundImg?.localFile?.childImageSharp.gatsbyImageData;
  const rrsImg = data.rrsImg?.localFile.childImageSharp.gatsbyImageData;
  const StyledBackground = styled(BgImage)`
    &::before,
    &::after {
      filter: brightness(
        ${({ isDarken }) => {
          return isDarken ? "40%" : "100%";
        }}
      );
    }
  `;
  const pluginImage = getImage(backgroundImg);

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": "https://www.hivehrsolutions.co.uk/locations/hr-guildford/#guildford",
    image: `https://hive-hr.rjmdigital.net/wp-content/uploads/2024/11/guildford-banner.png`,
    mpn: "https://www.hivehrsolutions.co.uk/locations/hr-guildford/",
    sku: "https://www.hivehrsolutions.co.uk/locations/hr-guildford/",
    description:
      "We offer companies a full range of HR services in Guildford. From people management to HR support, recruitment, training and procedures.",
    logo: `https://hive-hr.rjmdigital.net/wp-content/uploads/2021/12/HiveHR-Logo-Vertical-2021-GLOSSY-RGB.png`,
    name: "Hive HR Solutions",
    url: "https://www.hivehrsolutions.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://hive-hr.rjmdigital.net/wp-content/uploads/2021/12/HiveHR-Logo-Vertical-2021-GLOSSY-RGB.png`,
      image: `https://hive-hr.rjmdigital.net/wp-content/uploads/2021/12/pier.png`,
      name: "Hive HR Solutions",
      url: "https://www.hivehrsolutions.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `75`,
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.hivehrsolutions.co.uk/reviews",
      datePublished: "2022-08-30T17:58:29+00:00",
      reviewBody:
        "My experience in working with Hive HR Solutions was very much a positive one",
      author: {
        "@type": "Organization",
        name: "Voluntary Services Lewisham",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Hive HR Solutions",
        sameAs: "https://www.hivehrsolutions.co.uk/",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Locations",
        item: {
          url: `${siteUrl}/locations`,
          id: `${siteUrl}/locations`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Guildford",
        item: {
          url: `${siteUrl}/locations/hr-guildford`,
          id: `${siteUrl}/locations/hr-guildford`,
        },
      },
    ],
  };

  if (typeof window !== "undefined") {
    return (
      <Layout>
        <Helmet>
          {" "}
          <script type="application/ld+json">
            {JSON.stringify(breadcrumb)}
          </script>
        </Helmet>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(serviceSchema)}
          </script>
        </Helmet>

        <GatsbySeo
          title="Guildford HR Consultant | HR Support Guildford | Hive HR"
          description="We offer companies a full range of HR services in Guildford. From people management to HR support, recruitment, training and procedures."
          language="en"
          openGraph={{
            type: "website",
            url: `${siteUrl}/locations/hr-guildford`,
            title:
              "HR Consultant Guildford | Guildford HR Support | Hive HR Solutions",
            description:
              "We offer companies a full range of HR services. From people management to HR support, recruitment, training and procedures.",
            images: [
              {
                url: `${data.heroImg?.localFile?.publicURL}`,
                width: `${data.heroImg?.localFile?.childImageSharp.original.width}`,
                height: `${data.heroImg?.localFile?.childImageSharp.original.height}`,
                alt: `${data.heroImg?.altText}`,
              },
            ],
          }}
        />
        <section className="pt-md-4 pt-lg-0">
          <WhatWeDoHero
            hideSHeading
            pos="30%"
            title="Expert HR Consultancy in Guildford"
            img={
              <div>
                <Row className="g-0 d-lg-block d-none ">
                  <Col
                    style={{ overflow: "hidden" }}
                    className="g-0  pb-0   position-relative"
                  >
                    <GatsbyImage
                      style={{ width: "84%" }}
                      className=" tImage  mb-4"
                      alt="Expert HR Consultancy in Guildford"
                      image={heroImg}
                    />
                    <SVGPatternedSidebar
                      style={{ width: "20%", right: "-1rem" }}
                      className="position-absolute bg-primary tImage top-0  "
                    />
                  </Col>
                </Row>
                <GatsbyImage
                  className="w-100 d-lg-none mb-4"
                  alt={data.heroImg?.altText}
                  image={heroImg}
                />
              </div>
            }
          />
        </section>
        <section className="py-xl-6 position-relative">
          <SVGLcdBgPoly1
            style={{ zIndex: -2 }}
            className="position-absolute end-0 top--10 d-none d-lg-block "
          />
          <PhotoRight
            mobileOff={true}
            height="25rem"
            width="38rem"
            cta="Contact Us"
            ctaUrl="/contact-us"
            img={clientBaseImg}
            imgAlt="Hive HR consultants based in GuildFord"
            heading="Tailored Solutions for Your Business "
            text={
              <span>
                <p>
                  Are you seeking personalised HR support for your
                  Guildford-based business? Our expert HR consultants provide
                  tailored solutions to enhance your company's human resources
                  strategy.
                </p>
                <p>
                  At Hive HR Solutions, we understand that every business in
                  Guildford is unique. That's why we offer tailored HR solutions
                  to meet your specific needs and ensure your business thrives.
                </p>
              </span>
            }
          />
        </section>
        <section id="introduction" className="py-xl-6 position-relative">
          <SVGFBHp1 className="position-absolute end-0  d-none d-lg-block" style={{ top: "4rem" }}/>
          <SVGLargeBgBee
            style={{ height: "90rem", width: "90rem", top: "-8rem" }}
            className="position-absolute start-50 translate-middle-x d-none d-lg-block "
          />
          <Container className="py-lg-6 py-4 pt-md-0" style={{ zIndex: 3 }}>
            <h2 className="text-center">Our Core Services</h2>
            <Row className="pt-8 justify-content-center">
              <ServiceCard
                icon={<SVGBusiness width={100} />}
                header="Business Strategy"
                text="We align your HR practices with your business goals, ensuring every aspect of your human resources supports your company's growth and success. "
              />
              <ServiceCard
                icon={<SVGRecruitment width={100} />}
                header="Leadership & Management Development"
                text="We develop strong, effective leaders who can guide your business to new heights. Our tailored programmes are designed to nurture talent at all levels. "
              />
              <ServiceCard
                icon={<SVGErHp width={100} />}
                header="Employee Retention and Salary Benchmarking"
                text="Keep your best employees and attract top talent with competitive packages. We provide up-to-date salary benchmarking and develop retention strategies that work. "
              />
              <ServiceCard
                icon={<SVGSupport width={100} />}
                header="Support Services and In-house HR Team Development"
                text="Whether you need to bolster your existing HR team or build one from scratch, we provide the support and expertise you need. "
              />
              <ServiceCard
                icon={<SVGDocumentation width={100} />}
                header="Everyday HR Tasks"
                text="From recruitment and onboarding to performance management and compliance, we ensure your day-to-day HR operations run smoothly and efficiently. "
              />
            </Row>
          </Container>
        </section>

        <CtaBanner
          headline="Ready to Transform Your HR Strategy? "
          description="Book a call today and let's discuss how we can tailor our services to meet your unique needs. Our Guildford-based team is ready to help your business thrive. "
          btnTxt="Get in touch"
          btnLink="/contact-us"
          gradient={true}
          phoneText="01483 917416"
          phone="1483917416"
          style={{ zIndex: 3 }}
        />

        <section className=" position-relative py-6 py-xl-10 pb-10">
          <SVGFBLcd
            style={{ zIndex: -2, top: "-5%", left: "38%" }}
            className="position-absolute  translate-middle-x  d-none d-xl-block "
          />
          <HowCanWeHelpLeft
            listHeading="Discover Our Tailored Solutions"
            height="30rem"
            width="33rem"
            img={trainingImg}
            imgAlt="HR consultant providing HR support in the Hive HR office in Guildford"
            heading="How We Support Guildford Businesses "
            text={
              <div>
                <p>
                  We pride ourselves on delivering HR consultancy services that
                  are as unique as your business. Whether you're a start-up in
                  Guildford town centre or an established company in the Surrey
                  Research Park, we tailor our approach to suit your specific
                  industry, size and goals. 
                </p>
                <p>Our Guildford-based HR consultants offer:  </p>
                <ul>
                  <li className="mb-1">
                    Tailored HR strategies aligned with your business
                    objectives 
                  </li>
                  <li className="mb-1">
                    {" "}
                    Flexible support packages, from one-off projects to ongoing
                    partnerships 
                  </li>
                  <li className="mb-1">
                    {" "}
                    Local expertise combined with industry-wide best practices {" "}
                  </li>
                  <li className="mb-1">
                    Proactive problem-solving to prevent HR issues before they
                    arise 
                  </li>
                </ul>
              </div>
            }
            list={
              <p>
                Let us create a tailored HR handbook containing your values,
                ways of working and your policies and procedures for you and
                your employees.
              </p>
            }
          />
        </section>

        <section>
          <StyledBackground
            isDarken={isDarken}
            key={isDarken ? `dark` : `light`}
            className="pb-7 pt-xl-10"
            image={pluginImage}
          >
            <Container>
              <Row>
                <Col className="text-white mt-10">
                  <h2>
                    Maximise Your Business Potential with Expert HR Support 
                  </h2>
                  <p>
                    Imagine having a trusted HR partner who understands your
                    business inside and out. A team that not only handles your
                    day-to-day HR tasks but also helps shape your company's
                    future. That's what we offer at Hive HR Solutions. 
                  </p>
                </Col>
              </Row>
            </Container>
          </StyledBackground>
        </section>
        <section className=" py-6 position-relative">
          <SVGFBLcd
            style={{ zIndex: -2, top: "-8%", left: "-8%" }}
            className="position-absolute    d-none d-xl-block "
          />
          <PhotoRight
            height="25rem"
            width="38rem"
            img={rrsImg}
            imgAlt={data.rrsImg?.altText}
            heading="Why Choose Hive HR Solutions for Your HR Needs? "
            text={
              <div>
                <ul className="ps-3">
                  <li className="mb-1">
                    <b>Local knowledge:</b> we understand the Guildford business
                    landscape and the unique challenges it presents. 
                  </li>
                  <li className="mb-1">
                    <b>Personalised approach:</b> we don’t believe in one-size-fits-all
                    solution. Our expert team craft HR strategies that fit your
                    business perfectly. 
                  </li>
                  <li className="mb-1">
                    <b>Comprehensive expertise:</b> from start-ups to established
                    companies, we can help businesses across all sectors in
                    Guildford. 
                  </li>
                  <li className="mb-1">
                    <b>Proactive partners:</b> we don't just solve problems; we
                    anticipate and prevent them, saving you time and resources. 
                  </li>
                </ul>
              </div>
            }
          />
        </section>
      </Layout>
    );
  }
};

export default HRGuildFord;
